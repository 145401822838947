import * as React from "react"
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles"
import GlobalStyles from "@mui/joy/GlobalStyles"
import CssBaseline from "@mui/joy/CssBaseline"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import IconButton from "@mui/joy/IconButton"
import Link from "@mui/joy/Link"
import Typography from "@mui/joy/Typography"
import Stack from "@mui/joy/Stack"
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded"
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded"
import axios from "axios"

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props
  const { mode, setMode } = useColorScheme()
  const [mounted, setMounted] = React.useState(false)

  React.useEffect(() => setMounted(true), [])

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={event => {
        setMode(mode === "light" ? "dark" : "light")
        onClick?.(event)
      }}
      {...other}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  )
}

export default function SignInPage({user}) {

  React.useEffect(() => {
    if(user !== null && user !== 'loading') {
      if(!user.permissions['general.accessDashboard']) {
        window.location.pathname = '/perms'
      }
    }
  }, [user])

  function logout() {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`)
    .then((res) => {
        window.location.pathname = '/'
    })
  } 

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s" // set to `none` to disable transition
          }
        }}
      />
      <Box
        sx={theme => ({
          width: { xs: "100%", md: "50vw" },
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "rgba(255 255 255 / 0.2)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)"
          }
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width: "100%",
            px: 2
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
              <IconButton variant="soft" color="primary" size="sm">
                <img alt='KLM Crown' className='w-10' src={'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/KLM_crown_logo.svg/1200px-KLM_crown_logo.svg.png'} />
              </IconButton>
              <Typography level="title-lg">KLM Virtual Airline</Typography>
            </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: "hidden"
              }
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  KLM VA Logistics
                </Typography>
                <Typography level="body-sm">
                  {
                    user === null ? (
                      <div>
                        Want to join us?{' '}
                        <Link href="https://discord.gg/mheNW7pqw2" level="title-sm">
                          Join here
                        </Link>
                      </div>
                    ) : (
                      'Hello' + (user.username ? (' @' + user.username) : '') + ', please select an option'
                    )
                  }
                </Typography>
              </Stack>
              {
                user === null ? (
                <Button
                  variant="soft"
                  color="neutral"
                  fullWidth
                  onClick={() => {
                      window.location.href = user !== null ? '/app' : `${process.env.REACT_APP_BACKEND_URL}/api/auth/discord`
                  }}
                  startDecorator={<img alt='discord' className="w-5 mr-2" src='img/discord.png'/>}
                >
                  Continue with Discord
                </Button>
                ) : (
                  <div className="flex flex-col gap-y-2">
                    <Button
                      variant="soft"
                      color="neutral"
                      fullWidth
                      onClick={() => { window.location.href = '/app' }}
                    >
                      Dashboard
                    </Button>
                    <Button
                      variant="soft"
                      color="neutral"
                      fullWidth
                      onClick={logout}
                    >
                      Logout
                    </Button>
                  </div>
                )
              }
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © ChrisMC Developments {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={theme => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: "50vw" },
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "url(img/bg2.png)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage:
              "url(img/bg4.jpeg)"
          }
        })}
      />
    </CssVarsProvider>
  )
}
