import React, { useEffect, useState } from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import SignInPage from './pages/signin';
import axios from 'axios';
import ServerErrorPage from './pages/servererr';
import ErrorPage from './pages/error';
import NoPermissions from './pages/nopermissions';
import AppPage from './pages/auth/loader';
import { Toaster } from 'react-hot-toast';


function App() {
  const [user, setUser] = useState('loading')
  axios.defaults.withCredentials = true;

  const router = createBrowserRouter([
    {
      path: '/',
      element: <SignInPage user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/servererr',
      element: <ServerErrorPage />,
      errorElement: <ErrorPage />
    },
    {
      path: '/perms',
      element: <NoPermissions user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/app',
      element: <AppPage user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/app/flights',
      element: <AppPage user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/app/flights/:jobid',
      element: <AppPage user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/app/routes',
      element: <AppPage user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/app/fleet',
      element: <AppPage user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/app/hubs',
      element: <AppPage user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/app/pilots',
      element: <AppPage user={user} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/admin',
      element: <AppPage user={user} />,
      errorElement: <ErrorPage />
    },
  ]);


  const fetchAndSetCsrfToken = () => {
    if(window.location.pathname !== '/servererr') {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/csrf-token`)
      .then((response) => {
        const csrfToken = response.data.csrfToken;
        axios.defaults.headers.common['CSRF-Token'] = csrfToken;
      })
      .catch((error) => {
        console.error('Could not fetch CSRF token', error);
        window.location.pathname = '/servererr'
      });
    }
  };

  useEffect(() => {
    fetchAndSetCsrfToken();

    const axiosInterceptor = axios.interceptors.response.use(
      response => {
        const { method } = response.config;
        if (['post', 'put', 'delete', 'patch'].includes(method)) {
          fetchAndSetCsrfToken();
        }
        return response;
      }, error => {
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/profile`)
    .then((res) => {
      setUser(res.data !== 'unauth' ? res.data : null)
    })
    .catch((error) => {
      console.error('Could not fetch user', error);
      setUser(null)
    })
  }, [])


  return (
    <div className="App">
      <Toaster />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
