import { useState, useEffect } from "react";
import axios from "axios";
import { Box, Breadcrumbs, Link, Table, Typography, Button, Menu, MenuItem, ListItemDecorator, ListDivider, LinearProgress, MenuButton, Dropdown, Checkbox } from "@mui/joy";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import CellTowerIcon from '@mui/icons-material/CellTower';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import FlightIcon from '@mui/icons-material/Flight';
import TagIcon from '@mui/icons-material/Tag';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import MoreVert from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import HandymanIcon from '@mui/icons-material/Handyman';
import AircraftModal from "../../components/AircraftModal";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const FleetPage = ({ user }) => {
    const [fleet, setFleet] = useState([]);
    const [aircraftGroups, setAircraftGroups] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [softLoading, setSoftLoading] = useState(false);
    const [certificationList, setCertificationList] = useState([]);

    async function getData() {
        setSoftLoading(true);
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fleet`)
            .then(res => setFleet(res.data))
            .catch(err => console.error(err));

        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fleet/groups`)
            .then(res => setAircraftGroups(res.data))
            .catch(err => console.error(err));

        setSoftLoading(false);
    }

    useEffect(() => {
        setSoftLoading(true);
        getData();
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fleet/certifications`)
            .then(res => {
                setCertificationList(res.data)
            })
            .catch(err => console.error(err));
    }, []);

    const handleOpenModal = (data = null) => {
        setModalData(data);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalData(null);
    };

    const handleSubmit = (updatedData) => {
        setSoftLoading(true);
        if (modalData) {
            // Update
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fleet`, { aircraft: updatedData })
                .then(() => getData())
                .catch(err => console.error(err));
        } else {
            // Create
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/fleet`, { aircraft: {
                ...updatedData,
                id: undefined
            } })
                .then(() => getData())
                .catch(err => console.error(err));
        }
    };

    const handleDelete = (id) => {
        setSoftLoading(true);
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/fleet`, { data: { aircraftId: id } })
            .then(() => getData())
            .catch(err => console.error(err));
    };

    const sortedFleet = [...fleet].sort((a, b) => {
        // Find the group names
        const groupA = aircraftGroups.find(group => group.id === a.aircraftGroup.id)?.name || '';
        const groupB = aircraftGroups.find(group => group.id === b.aircraftGroup.id)?.name || '';
        
        // First, sort by group names
        if (groupA !== groupB) {
            return groupA.localeCompare(groupB);
        }
        
        // If group names are the same, sort by aircraft names
        return a.name.localeCompare(b.name);
    });
    

    const getAircraftGroupName = (groupId) => {
        const group = aircraftGroups.find(group => group.id === groupId);
        return group ? group.name : 'No Group';
    };

    return (
        <Box sx={{
            px: { xs: 2, md: 6 },
            pt: {
                xs: "calc(12px + var(--Header-height))",
                sm: "calc(12px + var(--Header-height))",
                md: 3
            },
            pb: { xs: 2, sm: 2, md: 3 }
        }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm" />}
                    sx={{ pl: 0 }}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        href="/"
                        aria-label="Home"
                    >
                        <HomeRoundedIcon />
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        KLM Fleet
                    </Typography>
                </Breadcrumbs>
            </Box>
            <div className={`my-2 ${!softLoading && 'opacity-0'}`}>
                <LinearProgress />
            </div>
            <Box
                sx={{
                    display: "flex",
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "start", sm: "center" },
                    flexWrap: "wrap",
                    justifyContent: "space-between"
                }}
            >
                <Typography level="h2" component="h1">
                    Fleet
                </Typography>
                {
                    user.permissions['admin'] && (
                        <Button variant="outlined" color="neutral" onClick={() => handleOpenModal()}>
                            New Aircraft
                        </Button>
                    )
                }
            </Box>
            <h1>A list of all owned aircraft that can be assigned to routes</h1>
            <div className="mt-10">
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <FlightIcon sx={{marginRight: '5px'}} />
                                Aircraft Name
                            </th>
                            <th>
                                <CellTowerIcon sx={{marginRight: '5px'}} />
                                Aircraft Code
                            </th>
                            <th>
                                <TagIcon sx={{marginRight: '5px'}} />
                                Registration
                            </th>
                            <th className="max-w-fit">
                                <ForkRightIcon sx={{marginRight: '5px'}} />
                                Assigned Routes
                            </th>
                            <th className="max-w-fit">
                                <ConnectingAirportsIcon sx={{marginRight: '5px'}} />
                                Aircraft Group
                            </th>
                            <th>
                                <WorkspacePremiumIcon sx={{marginRight: '5px'}} />
                                Certification Held
                            </th>
                            <th>
                                <PeopleAltIcon sx={{marginRight: '5px'}} />
                                Max Pax
                            </th>
                            <th>
                                <LocalShippingIcon sx={{marginRight: '5px'}} />
                                Max Cargo (kg)
                            </th>
                            {user.permissions['admin'] && (
                                <th style={{width: '5%'}}>
                                    <HandymanIcon sx={{marginRight: '5px'}} />
                                </th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {sortedFleet.map((aircraft) => (
                            <tr key={aircraft.id}>
                                <td>{aircraft.name}</td>
                                <td>{aircraft.aircraftCode}</td>
                                <td>{aircraft.registration}</td>
                                <td>{aircraft.assignedRoutes !== undefined && aircraft.assignedRoutes.length === 0 ? 'No Routes' : aircraft.assignedRoutes.map((route) => route.route.name).join(', ')}</td>
                                <td>{getAircraftGroupName(aircraft.aircraftGroup.id)}</td>
                                <td><Checkbox checked={certificationList.find((cert) => cert.aircraftId === aircraft.id) !== undefined} disabled={true} /></td>
                                <td>{aircraft.maxPax}</td>
                                <td>{aircraft.maxCargo}</td>
                                {user.permissions['admin'] && (
                                    <td>
                                        <Dropdown>
                                            <MenuButton
                                                aria-label="more"
                                                aria-controls={`menu-${aircraft.id}`}
                                                aria-haspopup="true"
                                            >
                                                <MoreVert />
                                            </MenuButton>
                                            <Menu
                                                id={`menu-${aircraft.id}`}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <MenuItem onClick={() => handleOpenModal(aircraft)}>
                                                    <ListItemDecorator>
                                                        <Edit />
                                                    </ListItemDecorator>
                                                    Edit
                                                </MenuItem>
                                                <ListDivider />
                                                <MenuItem variant="soft" color="danger" onClick={() => handleDelete(aircraft.id)}>
                                                    <ListItemDecorator sx={{ color: 'inherit' }}>
                                                        <DeleteForever />
                                                    </ListItemDecorator>
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </Dropdown>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </div>
            <AircraftModal
                open={modalOpen}
                onClose={handleCloseModal}
                onSubmit={handleSubmit}
                initialData={modalData}
                softLoading={softLoading}
                aircraftGroups={aircraftGroups}
            />
        </Box>
    );
};

export default FleetPage;
