import { useState, useEffect } from "react";
import axios from "axios";
import { Box, Breadcrumbs, Link, Table, Typography, Button, Menu, MenuItem, ListItemDecorator, ListDivider, LinearProgress, MenuButton, Dropdown } from "@mui/joy";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import CellTowerIcon from '@mui/icons-material/CellTower';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightIcon from '@mui/icons-material/Flight';
import MoreVert from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import HandymanIcon from '@mui/icons-material/Handyman';
import RouteModal from "../../components/RouteModal";

const RoutesPage = ({ user }) => {
    const [routes, setRoutes] = useState([]);
    const [aircraftGroups, setAircraftGroups] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [softLoading, setSoftLoading] = useState(false);

    async function getData() {
        setSoftLoading(true);
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/routes`)
            .then(res => {
                const sortedRoutes = res.data.sort((a, b) => a.name.localeCompare(b.name));
                setRoutes(sortedRoutes);
            })
            .catch(err => console.error(err));

        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/fleet/groups`)
            .then(res => setAircraftGroups(res.data))
            .catch(err => console.error(err));

        setSoftLoading(false);
    }

    useEffect(() => {
        setSoftLoading(true);
        getData();
    }, []);

    const handleOpenModal = (data = null) => {
        setModalData(data);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalData(null);
    };

    const handleSubmit = (updatedData) => {
        setSoftLoading(true);
        if (modalData) {
            // Update
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/routes`, { route: updatedData })
                .then(() => getData())
                .catch(err => console.error(err));
        } else {
            // Create
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/routes`, { route: { ...updatedData, id: undefined } })
                .then(() => getData())
                .catch(err => console.error(err));
        }
    };

    const handleDelete = (id) => {
        setSoftLoading(true);
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/routes`, { data: { routeId: id } })
            .then(() => getData())
            .catch(err => console.error(err));
    };

    return (
        <Box sx={{
            px: { xs: 2, md: 6 },
            pt: {
                xs: "calc(12px + var(--Header-height))",
                sm: "calc(12px + var(--Header-height))",
                md: 3
            },
            pb: { xs: 2, sm: 2, md: 3 }
        }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm" />}
                    sx={{ pl: 0 }}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        href="/"
                        aria-label="Home"
                    >
                        <HomeRoundedIcon />
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        KLM Company Routes
                    </Typography>
                </Breadcrumbs>
            </Box>
            <div className={`my-2 ${!softLoading && 'opacity-0'}`}>
                <LinearProgress />
            </div>
            <Box
                sx={{
                    display: "flex",
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "start", sm: "center" },
                    flexWrap: "wrap",
                    justifyContent: "space-between"
                }}
            >
                <Typography level="h2" component="h1">
                    Company Routes
                </Typography>
                {
                    user.permissions['management.modifyRoutes'] && (
                        <Button variant="outlined" color="neutral" onClick={() => handleOpenModal()}>
                            New Route
                        </Button>
                    )
                }
            </Box>
            <h1>These are flight routes that can be generated within jobs</h1>
            <div className="mt-10">
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <ForkRightIcon sx={{ marginRight: '5px' }} />
                                Route No.
                            </th>
                            <th style={{ width: '40%' }}>
                                <CellTowerIcon sx={{ marginRight: '5px' }} />
                                Waypoints
                            </th>
                            <th>
                                <FlightTakeoffIcon sx={{ marginRight: '5px' }} />
                                Departure Airport
                            </th>
                            <th>
                                <FlightLandIcon sx={{ marginRight: '5px' }} />
                                Arrival Airport
                            </th>
                            <th>
                                <FlightIcon sx={{ marginRight: '5px' }} />
                                Authorised Aircraft Groups
                            </th>
                            {
                                user.permissions['admin'] && (
                                    <th style={{ width: '5%' }}>
                                        <HandymanIcon sx={{ marginRight: '5px' }} />
                                    </th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {routes.map((route) => (
                            <tr key={route.id}>
                                <td>{route.name}</td>
                                <td>{route.waypoints}</td>
                                <td>{route.departureICAO}</td>
                                <td>{route.arrivalICAO}</td>
                                <td className="text-xs">
                                    <p className="max-h-[5vh] overflow-y-auto text-xs">
                                        {route.assignedGroups.map((g) => {
                                            return `${g.aircraftGroup.name}`
                                        }).join(', ')}
                                    </p>
                                </td>
                                {
                                    user.permissions['management.modifyRoutes'] && (
                                        <td>
                                            <Dropdown>
                                                <MenuButton
                                                    aria-label="more"
                                                    aria-controls={`menu-${route.id}`}
                                                    aria-haspopup="true"
                                                >
                                                    <MoreVert />
                                                </MenuButton>
                                                <Menu
                                                    id={`menu-${route.id}`}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <MenuItem onClick={() => handleOpenModal(route)}>
                                                        <ListItemDecorator>
                                                            <Edit />
                                                        </ListItemDecorator>
                                                        Edit
                                                    </MenuItem>
                                                    <ListDivider />
                                                    <MenuItem variant="soft" color="danger" onClick={() => handleDelete(route.id)}>
                                                        <ListItemDecorator sx={{ color: 'inherit' }}>
                                                            <DeleteForever />
                                                        </ListItemDecorator>
                                                        Delete
                                                    </MenuItem>
                                                </Menu>
                                            </Dropdown>
                                        </td>
                                    )
                                }
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <RouteModal
                open={modalOpen}
                onClose={handleCloseModal}
                onSubmit={handleSubmit}
                initialData={modalData}
                softLoading={softLoading}
                aircraftGroups={aircraftGroups}
            />
        </Box>
    );
};

export default RoutesPage;
