import { Avatar, Box, Breadcrumbs, Card, LinearProgress, Link, Table, Typography } from "@mui/joy";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded"
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import FlightIcon from '@mui/icons-material/Flight';
import { useEffect, useState } from "react";
import axios from "axios";

const HomePage = ({user}) => {

    const [earnings, setEarnings] = useState(0)
    const [assignedFlights, setAssignedFlights] = useState([])
    const [amountOfFlights, setAmountOfFlights] = useState(0)
    const [loading, setLoading] = useState(true)


    function getGreeting() {
        const now = new Date();
        const hour = now.getHours(); // gets the current hour (0-23)
    
        if (hour < 12) {
            return "Good morning"; // From midnight to 11:59 AM
        } else if (hour < 18) {
            return "Good afternoon"; // From 12 PM to 5:59 PM
        } else {
            return "Good evening"; // From 6 PM to midnight
        }
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/data/home`)
        .then((response) => {
            setEarnings(response.data.totalEarnings)
            setAssignedFlights(response.data.assignedFlights)
            setAmountOfFlights(response.data.totalFlights)
            setLoading(false)
        })
    }, [])

    return (
        <div className="relative">
            <div style={{
                position: 'absolute', // This makes the div a layer behind the main content
                width: '100%', // Covers the width of the container
                height: '100vh', // Covers the height of the viewport
                top: 0,
                left: 0,
                zIndex: -1, // Keeps it behind all other content
                backgroundImage: 'url("img/bg4.jpeg")',
                backgroundSize: 'cover', // Ensures the image covers the area
                backgroundPosition: 'center', // Centers the image
                filter: 'contrast(120%)', // Apply blur effect
                opacity: 0.5 // Apply opacity
            }} />
            {
                loading && <LinearProgress />
            }
            <Box sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: "calc(12px + var(--Header-height))",
                    sm: "calc(12px + var(--Header-height))",
                    md: 3
                },
                pb: { xs: 2, sm: 2, md: 3 } 
            }}>
                 
                <Box sx={{ 
                    display: "flex", 
                    alignItems: "center",
                }}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon fontSize="sm" />}
                    sx={{ pl: 0 }}
                >
                    <Link
                    underline="none"
                    color="neutral"
                    href="/"
                    aria-label="Home"
                    >
                    <HomeRoundedIcon />
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                    KLM Dashboard
                    </Typography>
                </Breadcrumbs>
                </Box>
                <Box
                sx={{
                    display: "flex",
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "start", sm: "center" },
                    flexWrap: "wrap",
                    justifyContent: "space-between"
                }}
                >
                    <Typography level="h2" component="h1">
                        Overview
                    </Typography>
                </Box>
                <h1>Your company details</h1>
                <div className="mt-10 grid md:grid-cols-2 gap-10">
                    <div className="flex items-center flex-col justify-center gap-3">
                        <Card sx={{flexGrow: 1, width: '100%'}}>
                            <div className="flex items-center gap-3">
                                <Avatar size="lg" src={`https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png`} />
                                <div>
                                    <Typography level="title-lg">{getGreeting()}, @{user.username}!</Typography>
                                    <Typography level="body-sm">Welcome to your overview</Typography>
                                </div>
                            </div>
                        </Card>
                        <Card sx={{flexGrow: 1, width: '100%', height: '30vh', overflowY: 'auto'}}>
                            <Typography level="title-lg">My Flights</Typography>
                            <Typography level="body-sm">These are your current assigned flights that you will earn points for completing</Typography>
                            <div className="flex flex-col gap-2">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <CellTowerIcon sx={{marginRight: '5px'}} />
                                                Callsign
                                            </th>
                                            <th>
                                                <FlightTakeoffIcon sx={{marginRight: '5px'}} />
                                                Flights
                                            </th>
                                            <th>
                                                <FlightLandIcon sx={{marginRight: '5px'}} />
                                                Arrival
                                            </th>
                                            <th>
                                                <FlightIcon sx={{marginRight: '5px'}} />
                                                Aircraft
                                            </th>
                                            <th>
                                                <ForkRightIcon sx={{marginRight: '5px'}} />
                                                Route
                                            </th>
                                            <th>
                                                <ConnectingAirportsIcon sx={{marginRight: '5px'}} />
                                                Job
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {
                                        //Display 5 flights sorted by callsign
                                        assignedFlights.sort((a, b) => (a.callsign > b.callsign) ? 1 : -1).map((flight, index) => {
                                                return (
                                                <tr className="flightRow cursor-pointer" onClick={() => window.location.pathname = '/app/flights/f_' + flight.id} key={index}>
                                                    <td>{flight.callsign}</td>
                                                    <td>{flight.route.departureICAO}</td>
                                                    <td>{flight.route.arrivalICAO}</td>
                                                    <td>{flight.aircraft.aircraftCode}</td>
                                                    <td>{flight.route.name}</td>
                                                    <td>{flight.job !== null ? `#${flight.job.id}` : `N/A`}</td>
                                                </tr>
                                                )
                                            })
                                       }
                                       {
                                        assignedFlights.length === 0 && <tr><td colSpan="6">No flights assigned</td></tr>
                                       }
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </div>
                    <div className="flex flex-col gap-3">
                        <Card sx={{flexGrow: 1, width: '100%'}}>
                            <Typography level="title-lg">My Contribution</Typography>
                            <Typography level="body-sm">You have earnt our company a total of</Typography>
                            <Typography level="h2">${earnings.toLocaleString()}</Typography>
                            <Typography level="body-sm">Thank you for your dedication and loyalty.</Typography>
                        </Card>
                        <Card sx={{flexGrow: 1, width: '100%'}}>
                            <Typography level="title-lg">My Past Flights</Typography>
                            <Typography level="body-sm">You have logged a total of</Typography>
                            <Typography level="h2">{amountOfFlights.toLocaleString()} {amountOfFlights === 1 ? 'Flight' : 'Flights'}</Typography>
                        </Card>
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default HomePage;