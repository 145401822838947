import { useState } from 'react';
import { Button, Modal, ModalDialog, DialogTitle, DialogContent, FormControl, FormLabel, Select, Option } from '@mui/joy';

function AddRoleModal({ availableRoles, roles, onSubmit, softLoading }) {
  const [open, setOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selectedRole);
    setOpen(false);
    setSelectedRole('');
  };

  return (
    <>
      <Button variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        New Role
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Add New Role</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel>Select Role</FormLabel>
                <Select value={selectedRole} onChange={(e, nv) => setSelectedRole(nv)} required>
                  <Option value="">Select a Role</Option>
                  {availableRoles.filter(role => !roles.find(r => r.id === role.id)).map(role => (
                    <Option key={role.id} value={role.id}>{role.name}</Option>
                  ))}
                </Select>
              </FormControl>
              <Button sx={{marginTop: '5px'}} fullWidth type="submit" disabled={softLoading} >Add Role</Button>
            </form>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
}


export default AddRoleModal