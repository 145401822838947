import { useState, useEffect } from 'react';
import { Modal, ModalDialog, DialogTitle, DialogContent, FormControl, FormLabel, Input, Select, Option, Button, Box, Chip } from '@mui/joy';

function RouteModal({ open, onClose, onSubmit, initialData, softLoading, aircraftGroups }) {
    const [name, setName] = useState('');
    const [waypoints, setWaypoints] = useState('');
    const [departureICAO, setDepartureICAO] = useState('');
    const [arrivalICAO, setArrivalICAO] = useState('');
    const [authorisedAircraftGroups, setAuthorisedAircraftGroups] = useState([]);

    useEffect(() => {
        if (initialData) {
            setName(initialData.name || '');
            setWaypoints(initialData.waypoints || '');
            setDepartureICAO(initialData.departureICAO || '');
            setArrivalICAO(initialData.arrivalICAO || '');
            setAuthorisedAircraftGroups(initialData.assignedGroups.map((group) => group.aircraftGroup.id) || []);
        }
    }, [initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedData = {
            id: initialData ? initialData.id : `temp-${Math.random()}`,
            name,
            waypoints,
            departureICAO,
            arrivalICAO,
            authorisedAircraftGroups
        };
        onSubmit(updatedData);
        onClose();
        setName('');
        setWaypoints('');
        setDepartureICAO('');
        setArrivalICAO('');
        setAuthorisedAircraftGroups([]);
    };

    const sortedAircraftGroups = aircraftGroups.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <DialogTitle>{initialData ? 'Update' : 'Create'} Route</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel>Route No.</FormLabel>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Route No."
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Waypoints</FormLabel>
                            <Input
                                value={waypoints}
                                onChange={(e) => setWaypoints(e.target.value)}
                                placeholder="Enter Waypoints"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Departure Airport</FormLabel>
                            <Input
                                value={departureICAO}
                                onChange={(e) => setDepartureICAO(e.target.value)}
                                placeholder="Enter Departure Airport"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Arrival Airport</FormLabel>
                            <Input
                                value={arrivalICAO}
                                onChange={(e) => setArrivalICAO(e.target.value)}
                                placeholder="Enter Arrival Airport"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Authorised Aircraft Groups</FormLabel>
                            <Select
                                multiple
                                value={authorisedAircraftGroups}
                                onChange={(e, nv) => setAuthorisedAircraftGroups(nv)}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', gap: '0.25rem', flexWrap: 'wrap' }}>
                                        {selected.map((selectedOption) => (
                                            <Chip key={selectedOption.value} variant="soft" color="primary">
                                                {selectedOption.label}
                                            </Chip>
                                        ))}
                                    </Box>
                                )}
                                sx={{
                                    minWidth: '15rem',
                                    maxWidth: '100%',  // Ensures the select doesn't grow beyond the modal width
                                }}
                                slotProps={{
                                    listbox: {
                                        sx: {
                                            width: '100%',
                                        },
                                    },
                                }}
                            >
                                {
                                    sortedAircraftGroups.map((group) => (
                                        <Option key={group.id} value={group.id}>
                                            {group.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <Button sx={{ marginTop: '10px' }} fullWidth type="submit" disabled={softLoading}>
                            {initialData ? 'Update' : 'Create'}
                        </Button>
                    </form>
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
}

export default RouteModal;
