import { useState } from 'react';
import { Button, Modal, ModalDialog, DialogTitle, DialogContent, FormControl, FormLabel, Input } from '@mui/joy';

function CreateAircraftGroupModal({ onSubmit, softLoading }) {
    const [open, setOpen] = useState(false);
    const [groupName, setGroupName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (groupName.trim()) {
            onSubmit(groupName);
            setOpen(false);
            setGroupName('');
        }
    };

    return (
        <>
            <Button variant="outlined" color="neutral" onClick={() => setOpen(true)}>
                New Aircraft Group
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog>
                    <DialogTitle>Create Aircraft Group</DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <FormControl>
                                <FormLabel>Name of the Aircraft Group</FormLabel>
                                <Input
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                    placeholder="Enter Aircraft Group Name"
                                    required
                                />
                            </FormControl>
                            <Button sx={{marginTop: '10px'}} fullWidth type="submit" disabled={softLoading}>
                                Create
                            </Button>
                        </form>
                    </DialogContent>
                </ModalDialog>
            </Modal>
        </>
    );
}

export default CreateAircraftGroupModal;
