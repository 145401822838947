import { useState } from 'react';
import { Button, Modal, ModalDialog, DialogTitle, DialogContent, Typography } from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteRoleModal({ selectedRole, onDelete, softLoading }) {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    onDelete(selectedRole);
    setOpen(false);
  };

  return (
    <>
      <Button disabled={selectedRole === 'none' || softLoading} color="danger" startDecorator={<DeleteIcon />} onClick={() => {if(selectedRole !== 'none') setOpen(true)}}>
        Delete Role
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Delete Role</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this role?</p>
            <div className='grid md:grid-cols-2 gap-2'>
                <Button variant='outlined' color='neutral' onClick={() => setOpen(false)}>Cancel</Button>
                <Button color="danger" onClick={handleDelete}>Delete</Button>
            </div>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default DeleteRoleModal