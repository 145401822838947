import { useState } from 'react';
import { Button, Modal, ModalDialog, DialogTitle, DialogContent, Typography } from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteAircraftGroupModal({ aircraftGroup, onDelete, softLoading }) {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    if (aircraftGroup.aircraft && aircraftGroup.aircraft.length > 0) {
      alert('Cannot delete group with assigned aircraft.');
      setOpen(false);
    } else {
      onDelete(aircraftGroup.id);
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        disabled={!aircraftGroup || softLoading || (aircraftGroup.aircraft && aircraftGroup.aircraft.length > 0)}
        color="danger"
        startDecorator={<DeleteIcon />}
        onClick={() => setOpen(true)}
      >
        Delete Group
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Delete Aircraft Group</DialogTitle>
          <DialogContent>
            <Typography component="p">
              Are you sure you want to delete the aircraft group '{aircraftGroup ? aircraftGroup.name : ''}'?
            </Typography>
            <div className='grid grid-cols-2 gap-2 mt-2'>
              <Button variant='outlined' color='neutral' onClick={() => setOpen(false)}>Cancel</Button>
              <Button color="danger" onClick={handleDelete}>Delete</Button>
            </div>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default DeleteAircraftGroupModal;
