import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { LinearProgress } from "@mui/joy";
import HomePage from "./home";
import AdminPage from "./adminpage";
import RoutesPage from "./routes";
import FleetPage from "./fleet";
import JobsPage from "./flights";

export default function AppPage({ user }) {

  const pages = {
    '/app': <HomePage user={user} />,
    '/app/flights': <JobsPage user={user} />,
    '/app/flights/:id': <JobsPage user={user} />,
    '/app/routes': <RoutesPage user={user} />,
    '/app/fleet': <FleetPage user={user} />,
    '/app/hubs': <HomePage />,
    '/app/pilots': <HomePage />,
    '/admin': <AdminPage user={user} />
  };

  const [currentPath, setCurrentPath] = React.useState(window.location.pathname);

  React.useEffect(() => {
    if (user === null) {
      window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/discord`;
    } else if(user !== 'loading' && !user.permissions['general.accessDashboard']) window.location.pathname = '/perms'

    const handleLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    // Listen for popstate event which occurs when the user clicks the back or forward button
    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, [user]);

  const matchPath = (path, currentPath) => {
    const pathParts = path.split('/').filter(Boolean);
    const currentPathParts = currentPath.split('/').filter(Boolean);

    if (pathParts.length !== currentPathParts.length) {
      return false;
    }

    return pathParts.every((part, index) => {
      return part.startsWith(':') || part === currentPathParts[index];
    });
  };

  const renderPage = () => {
    for (const path in pages) {
      if (matchPath(path, currentPath)) {
        return pages[path];
      }
    }
    return <div>Page not found</div>;
  };

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      {user !== 'loading' ? (
        <Box sx={{ display: "flex", minHeight: "100vh" }}>
          <Header />
          <Sidebar user={user} />
          <Box
            component="main"
            className="MainContent"
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              height: "100vh",
              overflowY: "auto",
              gap: 1
            }}
          >
            {renderPage()}
          </Box>
        </Box>
      ) : (
        <div className="w-screen p-10">
          <LinearProgress />
        </div>
      )}
    </CssVarsProvider>
  );
}
