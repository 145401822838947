import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  Table,
  Chip,
  Card,
  Modal,
  ModalDialog,
  CircularProgress,
} from "@mui/joy";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FlightJobsModal from "../../components/FlightGenerationModal";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import CellTowerIcon from "@mui/icons-material/CellTower";
import AirlinesIcon from "@mui/icons-material/Airlines";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import PaymentsIcon from "@mui/icons-material/Payments";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import ForkRightIcon from "@mui/icons-material/ForkRight";
import FlightIcon from "@mui/icons-material/Flight";
import toast from "react-hot-toast";
import LuggageIcon from "@mui/icons-material/Luggage";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DeleteConfirmationModal from "../../components/FlightJobDeletionConfirmation";
import { useParams } from "react-router-dom";

const JobsPage = ({ user }) => {
  const [flightJobsModalOpen, setFlightJobsModalOpen] = useState(false);
  const [fleet, setFleet] = useState([]);
  const [certifiedToFly, setCertifiedToFly] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [standaloneFlights, setStandaloneFlights] = useState([]);
  const [finalisedJobs, setFinalisedJobs] = useState([]);
  const [softLoading, setSoftLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState({
    open: false,
  });
  const params = useParams();

  useEffect(() => {
    if (params.jobid) {
      if (params.jobid.startsWith("f_")) {
        const flightId = params.jobid.substring(2);
        let flight = null;
        let job = null;

        // Check if the flight is within any job
        for (const currentJob of jobs) {
          flight = currentJob.flights.find((flight) => flight.id === flightId);
          if (flight) {
            job = currentJob;
            break;
          }
        }

        if (job) {
          setSelectedJob(job);
          setSelectedFlight(flight);
        } else {
          // If it's not within any job, check standalone flights
          flight = [...standaloneFlights, ...finalisedJobs].find(
            (flight) => flight.id === flightId
          );
          if (flight) {
            setSelectedFlight(flight);
            setSelectedJob(null);
          }
        }
      } else {
        const jobId = parseInt(params.jobid);
        const job = jobs.find((job) => job.id === jobId);
        if (job) {
          setSelectedJob(job);
          setSelectedFlight(null); // Clear any previously selected flight
        }
      }
    }
  }, [params, jobs, standaloneFlights, finalisedJobs]);

  const getData = async () => {
    try {
      const fleetResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/fleet`
      );
      setFleet(fleetResponse.data);

      const certifiedToFlyResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/fleet/certifications`
      );
      setCertifiedToFly(certifiedToFlyResponse.data);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/data/flights`
      );
      setJobs(response.data.jobs.filter((job) => !job.completed));
      setCompletedJobs(response.data.jobs.filter((job) => job.completed));
      setStandaloneFlights(response.data.standaloneFlights);
      setFinalisedJobs(response.data.finalisedJobs);
    } catch (error) {
      console.error(error);
    } finally {
      setSoftLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const openDeleteModal = (job) => {
    setDeleteModalOpen({
      open: true,
      job,
    });
  };

  const confirmDelete = async (jobId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/jobs/${jobId}`);
      getData(); // Refresh data after deletion
      toast.success("Job successfully deleted.");
    } catch (error) {
      console.error("Failed to delete job", error);
      toast.error("Failed to delete job.");
    }
  };

  const selectFlight = (flight) => {
    setSelectedFlight(flight);
    setSelectedJob(null); // Deselect job when a flight is selected
    window.history.pushState(null, null, `/app/flights/f_${flight.id}`);
  };

  const selectJob = (job) => {
    setSelectedJob(job);
    // Select the job.currentFlight in flights orderInJob
    const nextFlight = job.flights.find(
      (flight) => flight.orderInJob === job.currentFlight
    );
    setSelectedFlight(nextFlight);
    window.history.pushState(null, null, `/app/flights/${job.id}`);
  };

  const generateJobs = async (jobData) => {
    setSoftLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/jobs/new`,
        jobData
      );
      getData();
      if (response.data.error) {
        console.error(response.data.error);
        toast.error(response.data.error);
      } else {
        toast.success("Job successfully generated.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = () => {
    setFlightJobsModalOpen(true);
  };

  const deselectJob = () => {
    setSelectedJob(null);
    setSelectedFlight(null);
    window.history.pushState(null, null, `/app/flights`);
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}m ${secs}s`;
  };

  return (
    <Box
      sx={{
        px: { xs: 2, md: 6 },
        pt: {
          xs: "calc(12px + var(--Header-height))",
          sm: "calc(12px + var(--Header-height))",
          md: 3,
        },
        pb: { xs: 2, sm: 2, md: 3 },
      }}
    >
      <Modal open={softLoading}>
        <ModalDialog>
          <CircularProgress />
          <Typography lineHeight={1} level="title-md">
            Bare with us...
          </Typography>
          <Typography level="body-sm">
            This could take a while depending on parameters.
          </Typography>
        </ModalDialog>
      </Modal>

      <FlightJobsModal
        open={flightJobsModalOpen}
        onClose={() => setFlightJobsModalOpen(false)}
        fleet={fleet}
        softLoading={softLoading}
        generateJobs={generateJobs}
      />

      <DeleteConfirmationModal
        open={deleteModalOpen.open}
        setOpen={setDeleteModalOpen}
        onConfirm={confirmDelete}
        job={deleteModalOpen.job}
      />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="sm" />}
          sx={{ pl: 0 }}
        >
          <Link underline="none" color="neutral" href="/" aria-label="Home">
            <HomeRoundedIcon />
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            <button onClick={() => deselectJob()}>Job Manager</button>
          </Typography>
          {selectedJob !== null && (
            <Typography color="primary" fontWeight={500} fontSize={12}>
              KLM{selectedJob.id}
            </Typography>
          )}
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography startDecorator={<AirlinesIcon />} level="h2" component="h1">
          {selectedJob === null ? "Active Jobs" : `KLM #${selectedJob.id}`}
        </Typography>
        {user.permissions["general.generateFlights"] &&
          selectedJob === null &&
          selectedFlight === null && (
            <Button variant="outlined" color="neutral" onClick={() => handleOpenModal()}>
              Generate Jobs
            </Button>
          )}
        {(selectedJob !== null || selectedFlight !== null) && (
          <Button
            variant="outlined"
            color="neutral"
            startDecorator={<ConnectingAirportsIcon />}
            onClick={() => deselectJob()}
          >
            Back to Jobs
          </Button>
        )}
      </Box>

      {!selectedJob && !selectedFlight ? (
        !loading ? (
          <div>
            <Table>
              <thead>
                <tr>
                  <th>
                    <CellTowerIcon sx={{ marginRight: "5px" }} />
                    Job ID
                  </th>
                  <th>
                    <AirplaneTicketIcon sx={{ marginRight: "5px" }} />
                    Flights
                  </th>
                  <th>
                    <FlightLandIcon sx={{ marginRight: "5px" }} />
                    Flights Remaining
                  </th>
                  <th>
                    <AirlinesIcon sx={{ marginRight: "5px" }} />
                    Aircraft Used
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {jobs.map((job) => (
                  <tr key={job.id}>
                    <td>KLM{job.id}</td>
                    <td>{job.flights.length}</td>
                    <td>
                      {job.flights.length -
                        job.flights.filter((flight) => flight.arrivalTime !== null)
                          .length}
                    </td>
                    <td>
                      {(() => {
                        var aircraft = [];
                        job.flights.forEach((flight) => {
                          if (!aircraft.includes(flight.aircraft.name)) {
                            aircraft.push(flight.aircraft.name);
                          }
                        });

                        return aircraft.map((ac) => (
                          <Chip key={ac} color="primary">
                            {ac}
                          </Chip>
                        ));
                      })()}
                    </td>
                    <td className="flex items-center gap-2">
                      <Button size="sm" onClick={() => selectJob(job)}>
                        View Job
                      </Button>
                      <Button
                        color="danger"
                        variant="outlined"
                        size="sm"
                        onClick={() => openDeleteModal(job)}
                      >
                        Delete Job & Flights
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Typography
              startDecorator={<ConnectingAirportsIcon />}
              level="h2"
              sx={{ mt: 4 }}
            >
              Standalone Flights
            </Typography>
            <Table>
              <thead>
                <tr>
                  <th>
                    <CellTowerIcon sx={{ marginRight: "5px" }} />
                    Callsign
                  </th>
                  <th>
                    <FlightTakeoffIcon sx={{ marginRight: "5px" }} />
                    Departure
                  </th>
                  <th>
                    <FlightLandIcon sx={{ marginRight: "5px" }} />
                    Arrival
                  </th>
                  <th>
                    <FlightIcon sx={{ marginRight: "5px" }} />
                    Aircraft
                  </th>
                  <th>
                    <ForkRightIcon sx={{ marginRight: "5px" }} />
                    Route
                  </th>
                </tr>
              </thead>
              <tbody>
                {standaloneFlights.map((flight, index) => (
                  <tr
                    key={index}
                    className="flightRow cursor-pointer"
                    onClick={() => selectFlight(flight)}
                  >
                    <td>{flight.callsign}</td>
                    <td>{flight.route ? flight.route.departureICAO : 'N/A'}</td>
                    <td>{flight.route ? flight.route.arrivalICAO : 'N/A'}</td>
                    <td>{flight.aircraft.aircraftCode}</td>
                    <td>{flight.route ? flight.route.name : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Typography
              startDecorator={<ConnectingAirportsIcon />}
              level="h2"
              sx={{ mt: 4 }}
            >
              Finalised Flights
            </Typography>
            <Table>
              <thead>
                <tr>
                  <th>
                    <CellTowerIcon sx={{ marginRight: "5px" }} />
                    Callsign
                  </th>
                  <th>
                    <FlightTakeoffIcon sx={{ marginRight: "5px" }} />
                    Departure
                  </th>
                  <th>
                    <FlightLandIcon sx={{ marginRight: "5px" }} />
                    Arrival
                  </th>
                  <th>
                    <FlightIcon sx={{ marginRight: "5px" }} />
                    Aircraft
                  </th>
                  <th>
                    <ForkRightIcon sx={{ marginRight: "5px" }} />
                    Route
                  </th>
                  <th>
                    <PaymentsIcon sx={{ marginRight: "5px" }} />
                    Earnings
                  </th>
                  <th>
                    <HourglassBottomIcon sx={{ marginRight: "5px" }} />
                    Duration
                  </th>
                </tr>
              </thead>
              <tbody>
                {finalisedJobs.sort((a, b) => (a.callsign > b.callsign ? 1 : -1)).map((flight, index) => (
                  <tr
                    key={index}
                    className="flightRow cursor-pointer"
                    onClick={() => selectFlight(flight)}
                  >
                    <td>{flight.callsign}</td>
                    <td>{flight.route ? flight.route.departureICAO : 'N/A'}</td>
                    <td>{flight.route ? flight.route.arrivalICAO : 'N/A'}</td>
                    <td>{flight.aircraft.aircraftCode}</td>
                    <td>{flight.route ? flight.route.name : 'N/A'}</td>
                    <td>${flight.earnings.toLocaleString()}</td>
                    <td>{formatDuration(flight.duration)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Typography
              startDecorator={<ConnectingAirportsIcon />}
              level="h2"
              sx={{ mt: 4 }}
            >
              Completed Jobs
            </Typography>
            <Table>
              <thead>
                <tr>
                  <th>
                    <CellTowerIcon sx={{ marginRight: "5px" }} />
                    Job ID
                  </th>
                  <th>
                    <AirplaneTicketIcon sx={{ marginRight: "5px" }} />
                    Flights
                  </th>
                  <th>
                    <FlightLandIcon sx={{ marginRight: "5px" }} />
                    Flights Completed
                  </th>
                  <th>
                    <AirlinesIcon sx={{ marginRight: "5px" }} />
                    Aircraft Used
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {completedJobs.map((job) => (
                  <tr key={job.id}>
                    <td>KLM{job.id}</td>
                    <td>{job.flights.length}</td>
                    <td>
                      {job.flights.filter((flight) => flight.arrivalTime !== null)
                        .length}
                    </td>
                    <td>
                      {(() => {
                        var aircraft = [];
                        job.flights.forEach((flight) => {
                          if (!aircraft.includes(flight.aircraft.name)) {
                            aircraft.push(flight.aircraft.name);
                          }
                        });

                        return aircraft.map((ac) => (
                          <Chip key={ac} color="primary">
                            {ac}
                          </Chip>
                        ));
                      })()}
                    </td>
                    <td className="flex items-center gap-2">
                      <Button size="sm" onClick={() => selectJob(job)}>
                        View Job
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="flex my-10 justify-center items-center">
            <CircularProgress />
          </div>
        )
      ) : (
        <div>
          <Box>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="flex flex-col">
                <div className="grid grid-cols-2 gap-3 gap-y-3">
                  <Card size="sm">
                    <div className="flex items-center gap-5">
                      <CellTowerIcon />
                      <div>
                        <Typography level="body-sm">
                          {selectedJob ? "Job Callsign" : "Flight Callsign"}
                        </Typography>
                        <Typography level="body-lg">
                          {selectedJob ? `KLM${selectedJob.id}` : selectedFlight.callsign}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                  {selectedJob && (
                    <>
                      <Card size="sm">
                        <div className="flex items-center gap-5">
                          <FlightTakeoffIcon />
                          <div>
                            <Typography level="body-sm">Total Flights</Typography>
                            <Typography level="body-lg">
                              {selectedJob.flights.length} Flights
                            </Typography>
                          </div>
                        </div>
                      </Card>
                      <Card size="sm">
                        <div className="flex items-center gap-5">
                          <HourglassBottomIcon />
                          <div>
                            <Typography level="body-sm">
                              <b>BETA</b> Duration Estimator
                            </Typography>
                            <Typography level="body-lg">Coming Soon</Typography>
                          </div>
                        </div>
                      </Card>
                    </>
                  )}
                  {
                    selectedJob && (
                      <Card size="sm">
                        <div className="flex items-center gap-5">
                          <HourglassBottomIcon />
                          <div>
                            <Typography level="body-sm">
                              <b>BETA</b> Duration Estimator
                            </Typography>
                            <Typography level="body-lg">Coming Soon</Typography>
                          </div>
                        </div>
                      </Card>
                    )
                  }
                </div>
                <Card sx={{ marginTop: ".75rem" }}>
                  <Typography startDecorator={<FlightIcon />} level="title-lg">
                    Flight Information
                  </Typography>
                  {selectedFlight ? (
                    <div>
                      <Typography level="body-xs">{selectedFlight.id}</Typography>
                      <div className="mt-2 grid md:grid-cols-2 gap-3 gap-y-3">
                        <Card size="sm" sx={{ marginTop: ".75rem" }}>
                          <div className="flex items-center gap-3">
                            <CellTowerIcon color="primary" />
                            <div>
                              <Typography lineHeight={1.5} level="body-sm">
                                Callsign
                              </Typography>
                              <Typography lineHeight={1} level="body-md">
                                {selectedFlight.callsign}
                              </Typography>
                            </div>
                          </div>
                        </Card>
                        <Card size="sm" sx={{ marginTop: ".75rem" }}>
                          <div className="flex items-center gap-3">
                            <AirplaneTicketIcon />
                            <div>
                              <Typography lineHeight={1.5} level="body-sm">
                                Status
                              </Typography>
                              {selectedFlight.arrivalTime ? (
                                <Chip size="sm" color="success">
                                  Arrived
                                </Chip>
                              ) : (
                                <Chip size="sm" color="warning">
                                  Incomplete
                                </Chip>
                              )}
                            </div>
                          </div>
                        </Card>
                        <Card size="sm">
                          <div className="flex items-center gap-3">
                            <FlightTakeoffIcon color="primary" />
                            <div>
                              <Typography lineHeight={1.5} level="body-sm">
                                Departure
                              </Typography>
                              <Typography lineHeight={1} level="body-md">
                                {selectedFlight.route ? selectedFlight.route.departureICAO : 'N/A'}
                              </Typography>
                            </div>
                          </div>
                        </Card>
                        <Card size="sm">
                          <div className="flex items-center gap-3">
                            <FlightLandIcon color="primary" />
                            <div>
                              <Typography lineHeight={1.5} level="body-sm">
                                Arrival
                              </Typography>
                              <Typography lineHeight={1} level="body-md">
                                {selectedFlight.route ? selectedFlight.route.arrivalICAO : 'N/A'}
                              </Typography>
                            </div>
                          </div>
                        </Card>
                        <Card size="sm">
                          <div className="flex items-center gap-3">
                            <HourglassBottomIcon color="warning" />
                            <div>
                              <Typography lineHeight={1.5} level="body-sm">
                                {selectedFlight && selectedFlight.arrivalTime
                                  ? "Actual Duration"
                                  : "BETA Duration Estimator"}
                              </Typography>
                              <Typography lineHeight={1} level="body-md">
                                {selectedFlight && selectedFlight.arrivalTime
                                  ? formatDuration(selectedFlight.duration)
                                  : "Coming Soon"}
                              </Typography>
                            </div>
                          </div>
                        </Card>
                        <Card size="sm">
                          <div className="flex items-center gap-5">
                            <PaymentsIcon />
                            <div>
                              <Typography lineHeight={1.5} level="body-sm">
                                {selectedFlight && selectedFlight.arrivalTime
                                  ? "Actual Earnings"
                                  : "BETA Earnings Estimator"}
                              </Typography>
                              <Typography lineHeight={1} level="body-lg">
                                {selectedFlight && selectedFlight.arrivalTime
                                  ? `$${selectedFlight.earnings.toLocaleString()}`
                                  : "Coming Soon"}
                              </Typography>
                            </div>
                          </div>
                        </Card>
                        <Card size="sm">
                          <div className="flex flex-col">
                            <Typography level="body-sm" startDecorator={<ForkRightIcon />}>
                              Route
                            </Typography>
                            <Typography level="body-md">
                              {selectedFlight.route ? selectedFlight.route.waypoints : 'N/A'}
                            </Typography>
                          </div>
                        </Card>
                        <div>
                          <Card sx={{ marginBottom: ".75rem" }}>
                            <div className="flex flex-col">
                              <Typography level="body-sm" startDecorator={<AirlinesIcon />}>
                                Aircraft
                              </Typography>
                              <Typography level="body-md">
                                {selectedFlight.aircraft.name}
                              </Typography>
                              <Typography level="body-sm">
                                {selectedFlight.aircraft.aircraftCode}{" "}
                                {selectedFlight.aircraft.registration}
                              </Typography>
                            </div>
                          </Card>
                          <div className="grid grid-cols-2 gap-5">
                            <Card size="sm">
                              <div className="flex items-center gap-3">
                                <LuggageIcon />
                                <div>
                                  <Typography lineHeight={1.5} level="body-sm">
                                    Pax
                                  </Typography>
                                  <Typography lineHeight={1} level="body-md">
                                    {selectedFlight.pax}
                                  </Typography>
                                </div>
                              </div>
                            </Card>
                            <Card size="sm">
                              <div className="flex items-center gap-3">
                                <LocalShippingIcon />
                                <div>
                                  <Typography lineHeight={1.5} level="body-sm">
                                    Cargo
                                  </Typography>
                                  <Typography lineHeight={1} level="body-md">
                                    {(selectedFlight.cargo).toFixed(2)}kg
                                  </Typography>
                                </div>
                              </div>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Typography level="body-sm">
                      Please select a flight to view its information
                    </Typography>
                  )}
                </Card>
              </div>
              {selectedJob && (
                <Card variant="outlined">
                  <Typography startDecorator={<ConnectingAirportsIcon />} level="title-lg">
                    Flights
                  </Typography>
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }} />
                        <th>
                          <CellTowerIcon sx={{ marginRight: "5px" }} />
                          Callsign
                        </th>
                        <th>
                          <FlightTakeoffIcon sx={{ marginRight: "5px" }} />
                          Departure
                        </th>
                        <th>
                          <FlightLandIcon sx={{ marginRight: "5px" }} />
                          Arrival
                        </th>
                        <th>
                          <FlightIcon sx={{ marginRight: "5px" }} />
                          Aircraft
                        </th>
                        <th>
                          <ForkRightIcon sx={{ marginRight: "5px" }} />
                          Route
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {selectedJob.flights
                        .sort((a, b) => (a.orderInJob > b.orderInJob ? 1 : -1))
                        .map((flight, index) => (
                          <tr
                            key={flight.id}
                            onClick={() => setSelectedFlight(flight)}
                            className={`${
                              selectedFlight && selectedFlight.id === flight.id
                                ? "selectedRow"
                                : ""
                            } cursor-pointer`}
                          >
                            <td>{flight.orderInJob}</td>
                            <td>{flight.callsign}</td>
                            <td>{flight.route ? flight.route.departureICAO : 'N/A'}</td>
                            <td>{flight.route ? flight.route.arrivalICAO : 'N/A'}</td>
                            <td>{flight.aircraft.name}</td>
                            <td>{flight.route ? flight.route.name : 'N/A'}</td>
                            <td>
                              {flight.orderInJob === selectedJob.currentFlight &&
                              flight.arrivalTime === null ? (
                                <Chip size="sm" color="primary">
                                  Current Flight
                                </Chip>
                              ) : flight.arrivalTime !== null ? (
                                <Chip size="sm" color="success">
                                  Arrived
                                </Chip>
                              ) : (
                                <Chip size="sm" color="warning">
                                  Incomplete
                                </Chip>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card>
              )}
            </div>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default JobsPage;
