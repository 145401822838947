import { Box, Checkbox, Button, Select, Option, Input } from '@mui/joy';
import React from 'react';

function AircraftGroupDetails({ selectedAircraftGroupId, aircraftGroupsToUpdate, setAircraftGroupsToUpdate, roles, discordRoles, softLoading }) {
    const groupDetails = aircraftGroupsToUpdate.find(group => group.id === selectedAircraftGroupId) || {};

    const handleNameChange = (event) => {
        const updatedGroups = aircraftGroupsToUpdate.map(group =>
            group.id === selectedAircraftGroupId ? { ...group, name: event.target.value } : group
        );
        setAircraftGroupsToUpdate(updatedGroups);
    };

    const handleRequirePermsChange = (event) => {
        const updatedGroups = aircraftGroupsToUpdate.map(group =>
            group.id === selectedAircraftGroupId ? { ...group, requirePerms: event.target.checked } : group
        );
        setAircraftGroupsToUpdate(updatedGroups);
    };

    const handleRoleChange = (roleId, event) => {
        const updatedGroups = aircraftGroupsToUpdate.map(group => {
            if (group.id === selectedAircraftGroupId) {
                const updatedRequiredPerms = event.target.checked
                    ? [...group.requiredPerms, roleId]
                    : group.requiredPerms.filter(id => id !== roleId);
                return { ...group, requiredPerms: updatedRequiredPerms };
            }
            return group;
        });
        setAircraftGroupsToUpdate(updatedGroups);
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Input
                label="Aircraft Group Name"
                value={groupDetails.name || ''}
                onChange={handleNameChange}
                fullWidth
            />
            <Checkbox
                sx={{ mt: 2 }}
                label="Require Permissions"
                checked={groupDetails.requirePerms || false}
                onChange={handleRequirePermsChange}
            />
            <p>
                If the group requires permissions, only users with the selected roles will be able to be assigned aircarft within this group.
            </p>
            {groupDetails.requirePerms && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
                    {roles.map(role => (
                        <Checkbox
                            key={role.id}
                            label={discordRoles.find(discordRole => discordRole.id === role.id).name}
                            checked={groupDetails.requiredPerms.includes(role.id)}
                            onChange={(event) => handleRoleChange(role.id, event)}
                        />
                    ))}
                </Box>
            )}
            <h1 className='mt-5 font-bold'>Assigned Aircraft ({groupDetails.aircraft.length})</h1>
            <h1>This group {groupDetails.aircraft.length === 0 ? 'CAN' : 'CAN NOT'} be deleted due to amount of assigned aircraft.</h1>
            <h1>
                {
                groupDetails.aircraft.map((aircraft, index) => (
                    aircraft.aircraftCode
                ))
                }
            </h1>
        </Box>
    );
}

export default AircraftGroupDetails;
