import GlobalStyles from "@mui/joy/GlobalStyles"
import Avatar from "@mui/joy/Avatar"
import Box from "@mui/joy/Box"
import Divider from "@mui/joy/Divider"
import IconButton from "@mui/joy/IconButton"
import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton"
import ListItemContent from "@mui/joy/ListItemContent"
import Typography from "@mui/joy/Typography"
import Sheet from "@mui/joy/Sheet"
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded"
import AirlinesIcon from '@mui/icons-material/Airlines';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
//import PeopleIcon from '@mui/icons-material/People';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
//import LuggageIcon from '@mui/icons-material/Luggage';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import ColorSchemeToggle from "./ColorSchemeToggle"
import { closeSidebar } from "./utils"
import axios from "axios"

const pages = [
    {
        title: 'Dashboard',
        icon: <AirlinesIcon />,
        path: '/app'
    },
    {
        title: 'Flights',
        icon: <ConnectingAirportsIcon />,
        path: '/app/flights'
    },
    {
        title: 'Routes',
        icon: <ForkRightIcon />,
        path: '/app/routes'
    },
    {
        title: 'Fleet',
        icon: <FlightTakeoffIcon />,
        path: '/app/fleet'
    },
]

/*
function Toggler({ defaultExpanded = false, renderToggle, children }) {
  const [open, setOpen] = React.useState(defaultExpanded)
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden"
          }
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  )
}
*/

export default function Sidebar({user}) {

    function logout() {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`)
        .then((res) => {
            window.location.pathname = '/'
        })
    } 
  return  (
    <div className="border-[#00a1e4] border-l-4">
      <Sheet
        className="Sidebar"
        sx={{
          position: { xs: "fixed", md: "sticky" },
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
            md: "none"
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 10000,
          height: "100dvh",
          width: "var(--Sidebar-width)",
          top: 0,
          p: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider"
        }}
      >
        <GlobalStyles
          styles={theme => ({
            ":root": {
              "--Sidebar-width": "220px",
              [theme.breakpoints.up("lg")]: {
                "--Sidebar-width": "240px"
              }
            }
          })}
        />
        <Box
          className="Sidebar-overlay"
          sx={{
            position: "fixed",
            zIndex: 9998,
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            opacity: "var(--SideNavigation-slideIn)",
            backgroundColor: "var(--joy-palette-background-backdrop)",
            transition: "opacity 0.4s",
            transform: {
              xs:
                "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
              lg: "translateX(-100%)"
            }
          }}
          onClick={() => closeSidebar()}
        />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <IconButton variant="soft" color="primary" size="sm">
              <img alt='KLM crown' className='w-10' src={'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/KLM_crown_logo.svg/1200px-KLM_crown_logo.svg.png'} />
          </IconButton>
          <Typography level="title-lg">KLM</Typography>
          <ColorSchemeToggle sx={{ ml: "auto" }} />
        </Box>
        <Box
          sx={{
            minHeight: 0,
            overflow: "hidden auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            [`& .${listItemButtonClasses.root}`]: {
              gap: 1.5
            }
          }}
        >
          <List
            size="sm"
            sx={{
              gap: 1,
              "--List-nestedInsetStart": "30px",
              "--ListItem-radius": theme => theme.vars.radius.sm
            }}
          >
              {pages.map(({ title, icon, path }) => (
                  <ListItem key={title}>
                      <ListItemButton onClick={() => window.location.pathname = path} selected={path === '/app' ? window.location.pathname === '/app' : window.location.pathname.startsWith(path)}>
                      {icon}
                      <ListItemContent>
                          <Typography level="title-sm">{title}</Typography>
                      </ListItemContent>
                      </ListItemButton>
                  </ListItem>
              ))}
          </List>

          <List
            size="sm"
            sx={{
              mt: "auto",
              flexGrow: 0,
              "--ListItem-radius": theme => theme.vars.radius.sm,
              "--List-gap": "8px",
              mb: 2
            }}
          >
              {
                  user.permissions.admin && (
                      <ListItem>
                          <ListItemButton onClick={() => window.location.pathname = '/admin'} selected={window.location.pathname === '/admin'}>
                          <AdminPanelSettingsIcon />
                          Admin
                          </ListItemButton>
                      </ListItem>
                  )
              }
          </List>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Avatar
            variant="outlined"
            size="sm"
            src={`https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png`}
          />
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm">@{user.username}</Typography>
            <Typography level="body-xs">{user.discordId}</Typography>
          </Box>
          <IconButton onClick={logout} size="sm" variant="plain" color="neutral">
            <LogoutRoundedIcon />
          </IconButton>
        </Box>
      </Sheet>
    </div>
  )
}
