import { Box } from '@mui/joy';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';

function groupPermissions(permissions) {
    const grouped = { "Standalone Permissions": [] };  // Dedicated section for standalone permissions
    permissions.forEach(permission => {
        const parts = permission.split('.');
        if (parts.length === 1) {
            // Standalone permission
            grouped["Standalone Permissions"].push({
                value: permission,  // Original value
                label: permission.charAt(0).toUpperCase() + permission.slice(1)  // Display label
            });
        } else {
            // Grouped permissions
            const category = parts[0];
            const action = parts.slice(1).join('.');
            const formattedCategory = category.charAt(0).toUpperCase() + category.slice(1);

            if (!grouped[formattedCategory]) {
                grouped[formattedCategory] = [];
            }
            grouped[formattedCategory].push({
                value: `${category}.${action}`,  // Original value
                label: action.charAt(0).toUpperCase() + action.slice(1).replace(/([A-Z])/g, ' $1').trim()  // Display label
            });
        }
    });
    return grouped;
}

function PermissionsCheckboxes({ availablePerms, roles, selectedRoleId, onUpdate, softLoading }) {
    const [groupedPerms, setGroupedPerms] = useState(() => groupPermissions(availablePerms));
    const [rolePermissions, setRolePermissions] = useState([]);

    useEffect(() => {
        const role = roles.find(r => r.id === selectedRoleId);
        if (role) {
            setRolePermissions(role.permissions || []);
        }
        
    }, [roles, selectedRoleId]);

    const handleCheckboxChange = (permValue) => {
        const updatedPermissions = rolePermissions.includes(permValue)
            ? rolePermissions.filter(p => p !== permValue)
            : [...rolePermissions, permValue];
        setRolePermissions(updatedPermissions);
        onUpdate(selectedRoleId, updatedPermissions);
    };

    return (
        <Box sx={{ flexDirection: 'column' }}>
            {Object.entries(groupedPerms).map(([category, permissions]) => (
                <Box key={category} sx={{ mb: 2 }}>
                    <Typography level="h6" sx={{ mb: 1 }}>{category}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        {permissions.map(({ value, label }) => (
                            <Checkbox
                                disabled={softLoading}
                                key={value}
                                label={label}
                                checked={rolePermissions.includes(value)}
                                onChange={() => handleCheckboxChange(value)}
                            />
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    );
}

export default PermissionsCheckboxes;