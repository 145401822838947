import { Box, Button, Option, Select, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import axios from "axios";
import AddRoleModal from "../../components/Admin/CreateRoleModal";
import DeleteRoleModal from "../../components/Admin/DeleteRoleModal";
import PermissionsCheckboxes from "../../components/Admin/PermissionCheckbox";
import { LinearProgress } from "@mui/joy";
import toast from "react-hot-toast";
import CreateAircraftGroupModal from "../../components/Admin/CreateAircraftGroup";
import DeleteAircraftGroupModal from "../../components/Admin/DeleteAircraftGroupModal";
import AircraftGroupDetails from "../../components/Admin/AircraftGroupDetails";

const AdminPage = ({ user }) => {
	const [roles, setRoles] = useState([]);
	const [rolesToUpdate, setRolesToUpdate] = useState([]);
	const [availableRoles, setAvailableRoles] = useState([]);
	const [selectedRole, setSelectedRole] = useState("none");
	const [availablePerms, setAvailablePerms] = useState([]);
	const [loading, setLoading] = useState(true);
	const [softLoading, setSoftLoading] = useState(false);
	const [unsavedRoleChanges, setUnsavedRoleChanges] = useState(false);
	const [unsavedAirGroupChanges, setUnsavedAirGroupChanges] = useState(false);
	const [aircraftGroups, setAircraftGroup] = useState([]);
	const [aircraftGroupsToUpdate, setAircraftGroupsToUpdate] = useState([]);
	const [selectedAircraftGroup, setSelectedAircraftGroup] = useState("none");

	useEffect(() => {
		if (user === null) {
			window.location.pathname = "/";
		}

		if (!user.permissions.admin) {
			window.location.pathname = "/perms";
		}
	}, [user]);

	async function getRoleData(dsl) {
		const response = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/api/admin/bundle/perms`,
		);
		const data = response.data;
		setAvailablePerms(data.availablePerms);
		setRoles(data.roles);
		setRolesToUpdate(JSON.parse(JSON.stringify(data.roles))); // Deep copy of roles
		setAvailableRoles(data.availableRoles);
		if (!dsl) {
			setLoading(false);
			setSoftLoading(false);
		}
		return;
	}

	async function getAircraftGroupData(dsl) {
		const response = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/api/admin/aircraftgroups`,
		);
		const data = response.data;

		const groups = [];

		data.forEach((group) => {
			groups.push({
				id: group.id,
				name: group.name,
				aircraft: group.aircraft,
				requirePerms: group.requiresPermissions,
				requiredPerms: group.requireRoles.map((roleBind) => {
					return roleBind.role.id;
				}),
			});
		});

		setAircraftGroup(groups);
		setAircraftGroupsToUpdate(JSON.parse(JSON.stringify(groups)));
		if (!dsl) {
			setLoading(false);
			setSoftLoading(false);
		}
		return;
	}

	useEffect(() => {
		(async () => {
			if (user !== null && user.permissions.admin) {
				await getRoleData(true);
				await getAircraftGroupData(true);
				setLoading(false);
				setSoftLoading(false);
			}
		})();
	}, [user]);

	useEffect(() => {
		// Convert roles and rolesToUpdate to JSON strings and compare them
		const originalRoles = JSON.stringify(
			roles.map((role) => ({
				...role,
				permissions: role.permissions.sort(),
			})),
		);
		const updatedRoles = JSON.stringify(
			rolesToUpdate.map((role) => ({
				...role,
				permissions: role.permissions.sort(),
			})),
		);

		// Update unsavedRoleChanges based on whether the JSON strings are equal
		setUnsavedRoleChanges(originalRoles !== updatedRoles);
	}, [rolesToUpdate, roles]);

	useEffect(() => {
		// Convert aircraftGroups and aircraftGroupsToUpdate to JSON strings and compare them
		const originalGroups = JSON.stringify(
			aircraftGroups.map((group) => ({
				...group,
				requiredPerms: group.requiredPerms.sort(),
			})),
		);
		const updatedGroups = JSON.stringify(
			aircraftGroupsToUpdate.map((group) => ({
				...group,
				requiredPerms: group.requiredPerms.sort(),
			})),
		);

		// Update unsavedAirGroupChanges based on whether the JSON strings are equal
		setUnsavedAirGroupChanges(originalGroups !== updatedGroups);
	}, [aircraftGroupsToUpdate, aircraftGroups]);
	function addRole(roleId) {
		const roleData = availableRoles.find((role) => role.id === roleId);

		setRolesToUpdate([...rolesToUpdate, { ...roleData, permissions: [] }]);
		setSelectedRole(roleData.id);
		toast.success("Role added!");
	}

	function updateRoles(rolesToCreate, rolesToUpdate) {
		setSoftLoading(true);
		axios
			.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/roles`, {
				roles: rolesToCreate,
				rolesToUpdate: rolesToUpdate,
			})
			.then(async () => {
				await getRoleData();
				toast.success("Roles have been updated!");
			})
			.catch((err) => {
				console.error(err);
				setSoftLoading(false);
			});
	}

    function updateAircraftGroups(groupsToCreate, groupsToUpdate) {
        setSoftLoading(true);
        axios
            .post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/aircraftgroups`, {
                groups: groupsToCreate,
                groupsToUpdate: groupsToUpdate,
            })
            .then(async () => {
                await setSelectedAircraftGroup('none')
                await getAircraftGroupData();
                toast.success("Aircraft Groups have been updated!");
            })
            .catch((err) => {
                console.error(err);
                setSoftLoading(false);
            });
    }

	function createAircraftGroup(groupName) {
		const template = {
			id: `temp-${Math.floor(Math.random() * 1000000)}`,
			name: groupName,
			aircraft: [],
			requirePerms: false,
			requiredPerms: [],
		};
		setAircraftGroupsToUpdate([...aircraftGroupsToUpdate, template]);
		setSelectedAircraftGroup(template.id);
		toast.success("Aircraft Group created!");
	}

	function deleteAircraftGroup(groupId) {
		setSoftLoading(true);
		//Find if role is a 'to be created' role or an existing role by checking if the role exists in roles or only rolesToUpdate
		const groupExists = aircraftGroups.find((group) => group.id === groupId);
		if (!groupExists) {
			const newGroups = aircraftGroupsToUpdate.filter(
				(group) => group.id !== groupId,
			);
			setAircraftGroupsToUpdate(newGroups);
			setSelectedAircraftGroup("none");
			setSoftLoading(false);
			toast.success(
				"Aircraft Group had not been added to the database yet and has been removed.",
			);
		} else {
			axios
				.delete(
					`${process.env.REACT_APP_BACKEND_URL}/api/admin/aircraftgroup`,
					{
						data: {
							groupId: groupId,
						},
					},
				)
				.then(() => {
					toast.success(
						"Aircraft Group has been removed from the database and users with the role have been logged out.",
					);
					const newGroupsToUpdate = aircraftGroupsToUpdate.filter(
						(group) => group.id !== groupId,
					);
					setAircraftGroupsToUpdate(newGroupsToUpdate);
					const newGroups = aircraftGroups.filter(
						(group) => group.id !== groupId,
					);
					setAircraftGroup(newGroups);
					setSelectedAircraftGroup("none");
					setSoftLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setSoftLoading(false);
				});
		}
	}

	function deleteRole(roleId) {
		setSoftLoading(true);
		//Find if role is a 'to be created' role or an existing role by checking if the role exists in roles or only rolesToUpdate
		const roleExists = roles.find((role) => role.id === roleId);
		if (!roleExists) {
			const newRoles = rolesToUpdate.filter((role) => role.id !== roleId);
			setRolesToUpdate(newRoles);
			setSelectedRole("none");
			setSoftLoading(false);
			toast.success(
				"Role had not been added to the database yet and has been removed.",
			);
		} else {
			axios
				.delete(`${process.env.REACT_APP_BACKEND_URL}/api/admin/role`, {
					data: {
						roleId: roleId,
					},
				})
				.then(() => {
					toast.success(
						"Role has been removed from the database and users with the role have been logged out.",
					);
					const newRolesToUpdate = rolesToUpdate.filter(
						(role) => role.id !== roleId,
					);
					setRolesToUpdate(newRolesToUpdate);
					const newRoles = roles.filter((role) => role.id !== roleId);
					setRoles(newRoles);
					setSelectedRole("none");
					setSoftLoading(false);
				})
				.catch((err) => {
					console.error(err);
					setSoftLoading(false);
				});
		}
	}

	return (
		user !== null &&
		user.permissions.admin && (
			<Box
				sx={{
					px: { xs: 2, md: 6 },
					pt: {
						xs: "calc(12px + var(--Header-height))",
						sm: "calc(12px + var(--Header-height))",
						md: 3,
					},
					pb: { xs: 2, sm: 2, md: 3 },
				}}
			>
				<Box
					sx={{
						display: "flex",
						mb: 1,
						gap: 1,
						flexDirection: { xs: "column", sm: "row" },
						alignItems: { xs: "start", sm: "center" },
						flexWrap: "wrap",
						justifyContent: "space-between",
					}}
				>
					<Typography level="h2" component="h1">
						Administration
					</Typography>
				</Box>
				<h1>Site Settings</h1>

				{loading && (
					<div className="max-w-5xl m-10 mx-auto">
						<LinearProgress />
					</div>
				)}

				<div className={`${loading && "hidden"} max-w-5xl mx-auto`}>
					<h1 className="font-semibold text-2xl mt-5">Role Permissions</h1>
					<div className="flex items-center gap-2">
						<Select
							sx={{ width: "70%" }}
							value={selectedRole}
							onChange={(e, nv) => setSelectedRole(nv)}
						>
							<Option value="none">Select a Role</Option>
							{rolesToUpdate.map((role) => {
								let roleData = availableRoles.find((r) => r.id === role.id);
								return (
									<Option key={role.id} value={role.id}>
										<div className="flex items-center gap-2">
											<div
												style={{
													backgroundColor: roleData.color,
													width: "15px",
													height: "15px",
													borderRadius: "100%",
												}}
											/>
											{roleData ? roleData.name : "Deleted Role"} ({role.id})
										</div>
									</Option>
								);
							})}
						</Select>
						<AddRoleModal
							availableRoles={availableRoles}
							roles={rolesToUpdate}
							onSubmit={addRole}
							softLoading={softLoading}
						/>
						<DeleteRoleModal
							selectedRole={selectedRole}
							onDelete={deleteRole}
							softLoading={softLoading}
						/>
					</div>
					{selectedRole !== "none" && (
						<PermissionsCheckboxes
							availablePerms={availablePerms}
							roles={rolesToUpdate}
							selectedRoleId={selectedRole}
							softLoading={softLoading}
							onUpdate={(roleId, updatedPermissions) => {
								const index = rolesToUpdate.findIndex((r) => r.id === roleId);
								if (index !== -1) {
									const newRolesToUpdate = [...rolesToUpdate];
									newRolesToUpdate[index].permissions = updatedPermissions;
									setRolesToUpdate(newRolesToUpdate);
								}
							}}
						/>
					)}
					{unsavedRoleChanges && (
						<div>
							<Typography color="danger" level="h4">
								<b>You have unsaved changes!</b>
							</Typography>
							<Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
								<Button
									variant="solid"
									color="primary"
									disabled={softLoading}
									onClick={() => {
										const findNewRoles = (rolesToUpdate, roles) => {
											return rolesToUpdate.filter(
												(rToUpdate) =>
													!roles.some((r) => r.id === rToUpdate.id),
											);
										};

										const findUpdatedRoles = (rolesToUpdate, roles) => {
											return rolesToUpdate.filter((rToUpdate) => {
												const originalRole = roles.find(
													(r) => r.id === rToUpdate.id,
												);
												return (
													originalRole &&
													JSON.stringify(rToUpdate.permissions.sort()) !==
														JSON.stringify(originalRole.permissions.sort())
												);
											});
										};

										updateRoles(
											findNewRoles(rolesToUpdate, roles),
											findUpdatedRoles(rolesToUpdate, roles),
										);
									}}
								>
									Save Changes
								</Button>
								<Button
									variant="outlined"
									disabled={softLoading}
									color="danger"
									onClick={() => {
										setRolesToUpdate(JSON.parse(JSON.stringify(roles))); // Revert changes
										setSelectedRole("none");
									}}
								>
									Revert Changes
								</Button>
							</Box>
						</div>
					)}
					<div className={`${loading && "hidden"} max-w-5xl mx-auto`}>
						<h1 className="font-semibold text-2xl mt-5">Aircraft Categories</h1>
						<div className="flex items-center gap-2">
							<Select
								sx={{ width: "60%" }}
								value={selectedAircraftGroup}
								onChange={(e, nv) => setSelectedAircraftGroup(nv)}
							>
								<Option value="none">Select an aircraft category</Option>
								{aircraftGroupsToUpdate.map((aircraftGroup) => {
									return (
										<Option key={aircraftGroup.id} value={aircraftGroup.id}>
											<div className="flex items-center gap-2">
												{aircraftGroup.name} ({aircraftGroup.id})
											</div>
										</Option>
									);
								})}
							</Select>
							<CreateAircraftGroupModal
								availableRoles={availableRoles}
								roles={roles}
								onSubmit={createAircraftGroup}
								softLoading={softLoading}
							/>
							<DeleteAircraftGroupModal
								aircraftGroup={aircraftGroupsToUpdate.find(
									(group) => group.id === selectedAircraftGroup,
								)}
								onDelete={deleteAircraftGroup}
								softLoading={softLoading}
							/>
						</div>
						{selectedAircraftGroup !== "none" && (
							<AircraftGroupDetails
								selectedAircraftGroupId={selectedAircraftGroup}
								aircraftGroupsToUpdate={aircraftGroupsToUpdate}
								setAircraftGroupsToUpdate={setAircraftGroupsToUpdate}
								discordRoles={availableRoles}
								roles={roles}
								softLoading={softLoading}
							/>
						)}
						{unsavedAirGroupChanges && (
							<div>
								<Typography color="danger" level="h4">
									<b>You have unsaved changes!</b>
								</Typography>
								<Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
									<Button
										variant="solid"
										color="primary"
										disabled={softLoading}
										onClick={() => {
											const findNewGroups = (
                                                aircraftGroupsToUpdate,
                                                aircraftGroups,
                                            ) => {
                                                return aircraftGroupsToUpdate.filter(
                                                    (groupToUpdate) =>
                                                        !aircraftGroups.some(
                                                            (group) => group.id === groupToUpdate.id,
                                                        ),
                                                );
                                            }

                                            const findUpdatedGroups = (aircraftGroupsToUpdate, aircraftGroups) => {
                                                return aircraftGroupsToUpdate.filter(groupToUpdate => {
                                                    const originalGroup = aircraftGroups.find(group => group.id === groupToUpdate.id);
                                                    if (!originalGroup) return false; // If no original group is found, no update is needed
                                            
                                                    // Check if permissions have changed
                                                    const permsChanged = JSON.stringify(groupToUpdate.requiredPerms.sort()) !== JSON.stringify(originalGroup.requiredPerms.sort());
                                            
                                                    // Check if the name has changed
                                                    const nameChanged = groupToUpdate.name !== originalGroup.name;
                                            
                                                    // Return true if either name or permissions have changed
                                                    return permsChanged || nameChanged;
                                                });
                                            }
                                            

                                            updateAircraftGroups(
                                                findNewGroups(aircraftGroupsToUpdate, aircraftGroups),
                                                findUpdatedGroups(aircraftGroupsToUpdate, aircraftGroups),
                                            );
										}}
									>
										Save Changes
									</Button>
									<Button
										variant="outlined"
										disabled={softLoading}
										color="danger"
										onClick={() => {
											setAircraftGroupsToUpdate(JSON.parse(JSON.stringify(aircraftGroups))); // Revert changes
											setSelectedAircraftGroup("none");
										}}
									>
										Revert Changes
									</Button>
								</Box>
							</div>
						)}
					</div>
				</div>
			</Box>
		)
	);
};

export default AdminPage;
