import { useState } from 'react';
import { Modal, ModalDialog, DialogTitle, DialogContent, FormControl, FormLabel, Input, Select, Option, Checkbox, Button, Box } from '@mui/joy';

function FlightJobsModal({ open, onClose, fleet, airports, softLoading, generateJobs }) {
    const [maxFlights, setMaxFlights] = useState(5);
    const [allowFerryFlights, setAllowFerryFlights] = useState(false);
    const [selectedAircraft, setSelectedAircraft] = useState([]);
    const [startingAirport, setStartingAirport] = useState('');
    const [includedAirports, setIncludedAirports] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        generateJobs({
            maxFlights,
            allowFerryFlights,
            preferredAircraftIds: selectedAircraft,
            startingAirport,
            includedAirports: includedAirports.split(',')
        });
        onClose(); // Close the modal after submission
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <DialogTitle>Generate a Job</DialogTitle>
                <DialogContent>
                    <form className='flex flex-col gap-2 p-2' onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel>Max Flights</FormLabel>
                            <p className='text-xs mb-2'>
                                This means the maximum amount of flights the system will generate in a job. If the system runs out of available routes or aircraft, it will stop generating flights before this number is reached.
                            </p>
                            <Input
                                type="number"
                                value={maxFlights}
                                onChange={(e) => setMaxFlights(Number(e.target.value))}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Allow Ferry Flights</FormLabel>
                            <p className='text-xs mb-2'>
                                Enabling 'Allow Ferry Flights' allows the system to create flight jobs that include legs where no direct routes exist due to aircraft certification or other configuration restrictions. If selected, the pilot is expected to arrange their own transport between the two non-connected airports, simulating the process of transferring an aircraft without carrying commercial passengers or cargo.
                            </p>
                            <Checkbox
                                checked={allowFerryFlights}
                                onChange={(e) => setAllowFerryFlights(e.target.checked)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Starting Airport (Optional)</FormLabel>
                            <p className='text-xs mb-2'>
                                This means the system will start the job at the specified airport, useful if there is only one controlled airport. If you configure included airports below, you must include this airport in that list.
                            </p>
                            <Input 
                                value={startingAirport}
                                onChange={(e) => setStartingAirport(e.target.value)}
                                placeholder='KLSX'
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Include Airports (Optional)</FormLabel>
                            <p className='text-xs mb-2'>
                                This means the system will only give you routes including the following airports, useful if there are multiple controlled airports. Otherwise, if there is only one controlled airport, you should use the starting airport.
                            </p>
                            <Input
                                value={includedAirports}
                                placeholder='KLSX,KPFC'
                                //Remove whitespace and split by comma
                                onChange={(e) => {
                                    setIncludedAirports(e.target.value.toUpperCase().replace(/\s/g, ''));
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Choose Aircraft (Leave blank for all available)</FormLabel>
                            <p className='text-xs mb-2'>
                                If you wish to fly a certain plane(s), you can include your preferred aircraft here. If the system needs to change aircraft for route-related reasons, it will choose from the specified aircraft.
                            </p>
                            <Select
                                multiple
                                value={selectedAircraft}
                                onChange={(e, nv) => setSelectedAircraft(nv)}
                            >
                                {fleet.sort((a, b) => (a.name > b.name) ? 1 : -1).map(aircraft => (
                                    <Option key={aircraft.id} value={aircraft.id}>
                                        {aircraft.name} ({aircraft.registration})
                                    </Option>
                                ))}
                            </Select>
                        </FormControl>
                        <Button sx={{ marginTop: '10px' }} fullWidth type="submit" disabled={softLoading}>
                            Generate Flights
                        </Button>
                    </form>
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
}

export default FlightJobsModal;
