import { useState } from 'react';
import { Button, Modal, ModalDialog, DialogTitle, DialogContent, Typography } from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteConfirmationModal({ job, onConfirm, open, setOpen, onClose }) {

    const handleDelete = () => {
        onConfirm(job.id);
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={() => setOpen({open: false, job: null})}>
            <ModalDialog>
                <DialogTitle>Delete Job</DialogTitle>
                <DialogContent>
                    <Typography component="p">
                        Are you sure you want to delete the job '{job ? `KLM${job.id}` : ''}'?
                    </Typography>
                    <div className='grid grid-cols-2 gap-2 mt-2'>
                        <Button variant='outlined' color='neutral' onClick={() => setOpen(false)}>Cancel</Button>
                        <Button color="danger" onClick={handleDelete}>Delete</Button>
                    </div>
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
}

export default DeleteConfirmationModal;
