import { useState, useEffect } from 'react';
import { Modal, ModalDialog, DialogTitle, DialogContent, FormControl, FormLabel, Input, Select, Option, Button } from '@mui/joy';

function AircraftModal({ open, onClose, onSubmit, initialData, softLoading, aircraftGroups }) {
    const [aircraftName, setAircraftName] = useState('');
    const [aircraftCode, setAircraftCode] = useState('');
    const [registration, setRegistration] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [maxPax, setMaxPax] = useState('');
    const [maxCargo, setMaxCargo] = useState('');

    useEffect(() => {
        if (initialData) {
            setAircraftName(initialData.name || '');
            setAircraftCode(initialData.aircraftCode || '');
            setRegistration(initialData.registration || '');
            setSelectedGroup(initialData.aircraftGroupId || '');
            setMaxPax(initialData.maxPax || '');
            setMaxCargo(initialData.maxCargo || '');
        }
    }, [initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedData = {
            id: initialData ? initialData.id : `temp-${Math.random(0, 999999)}`,
            name: aircraftName,
            aircraftCode: aircraftCode,
            registration: registration,
            aircraftGroupId: selectedGroup,
            maxPax: parseInt(maxPax),
            maxCargo: parseInt(maxCargo)
        };
        onSubmit(updatedData);
        onClose();
        setAircraftName('');
        setAircraftCode('');
        setRegistration('');
        setSelectedGroup('');
        setMaxPax('');
        setMaxCargo('');
    };

    return (
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <DialogTitle>{initialData ? 'Update' : 'Create'} Aircraft</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel>Aircraft Name</FormLabel>
                            <Input
                                value={aircraftName}
                                onChange={(e) => setAircraftName(e.target.value)}
                                placeholder="Enter Aircraft Name"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Aircraft Code</FormLabel>
                            <Input
                                value={aircraftCode}
                                onChange={(e) => setAircraftCode(e.target.value)}
                                placeholder="Enter Aircraft Code"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Registration</FormLabel>
                            <Input
                                value={registration}
                                onChange={(e) => setRegistration(e.target.value)}
                                placeholder="Enter Registration"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Aircraft Group</FormLabel>
                            <Select
                                value={selectedGroup}
                                onChange={(e, newValue) => setSelectedGroup(newValue)}
                                placeholder="Select Aircraft Group"
                                required
                            >
                                {aircraftGroups.map((group) => (
                                    <Option key={group.id} value={group.id}>
                                        {group.name}
                                    </Option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Max Passengers</FormLabel>
                            <Input
                                type="number"
                                value={maxPax}
                                onChange={(e) => setMaxPax(e.target.value)}
                                placeholder="Enter max passengers"
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Max Cargo (kg)</FormLabel>
                            <Input
                                type="number"
                                value={maxCargo}
                                onChange={(e) => setMaxCargo(e.target.value)}
                                placeholder="Enter max cargo in kg"
                                required
                            />
                        </FormControl>
                        <Button sx={{marginTop: '10px'}} fullWidth type="submit" disabled={softLoading}>
                            {initialData ? 'Update' : 'Create'}
                        </Button>
                    </form>
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
}

export default AircraftModal;
